//define transitions
$transition: all 0.1s ease-in-out, border 0.8s ease-in-out;

.cardStack {
  .card-image {
    margin-bottom:  map-get($spacers, 3);
    background: $primary;
  }

  a {
    text-decoration: none;
    color: $body-color;

    h1, h2, h3 {
      color: $headings-color;
    }

    h4, h5, h6 {
      color: $dark;
    }
    .button {
      $transition: $transition;
      color: $primary;
      text-decoration: underline;

    }
    .card-footer {
      background: none;
    }
    &:hover {
      .card {
        transition: $transition;
        border: 1px solid $primary;
        background: $light;
      }
      .card-image {
        img {
          opacity: 0.8;
        }
      }
      .button {
        transition: $transition;
        margin-left: 5px;
        &:after {
          font-family: 'Font Awesome 5 Free';
          font-weight: 900;
          content: ' \f061';
        }
      }
    }
  }
}