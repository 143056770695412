.infobox--mobile {
  position: fixed;
  z-index: 1000;
}


.awningManufacturerLogo {
  max-height: 100px;
}

.awningCard {
  //hide price hack
  div div div p {
    display: none;
  }
  .blocking {
    text-decoration: line-through;
  }
  .disabled {
    h1, h2, h3, h4, h5, p, dd, dt, .btn {
      color: $mediumLight !important;
    }
    img {
      filter: grayscale(1);
      opacity: 0.5;
    }
    .btn {
      background-color: $light !important;
      border-color: $light !important;
    }
  }
}


.awningApp {
  .item {
    min-height: 130px;
    .itemContent {
      cursor: pointer;
      &:hover {
        background: $light;
      }
      .caption {
        text-transform: uppercase;
        font-weight: 500;
      }
    }
    .disabled {
      color: $medium !important;
    }
    .selected {
      border: 1px solid $primary;
      background: $light;
    }
  }
  .price {
    font-weight: 500;
  }
  .card-header {
    &:hover {
      cursor: pointer;
    }
  }

  .accordion {
    .btn {
      text-transform: none;
    }
  }
}
