.mm-listview {
  text-align: left;
}
.header .navigation ul ul {
  display: none;
}

  //Align left hover fix
.navigation {
  font-size: 1.1rem;
  text-transform: uppercase;;
  text-align: right;
  z-index: 10;
  margin-left: -10px;
  padding-right: 0;

  .navigation-list, .navigation-list * {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .navigation-list li {
    text-align: left;
    position: relative;
    white-space: nowrap;
  }
  .navigation-list ul {
    position: absolute;
    display: none;
    top: 100%;
    left: 0;
    z-index: 99;
  }
  .navigation-list > li {
    display: inline-block;
  }
  .navigation-list > .metaChild {
    display: none;
  }
  .navigation-list li:hover > ul,
  .navigation-list li.sfHover > ul {
    display: block;
  }

  .navigation-list a {
    display: block;
    position: relative;
  }
  .navigation-list ul ul {
    top: 0;
    left: 100%;
  }




  .navigation-list {
    li a {
      height: 50px;
      line-height: 50px;
      padding: 0 10px;
      color: $primary;

      &:hover {
        background: $light;
        text-decoration: none;
        transition: none;
      }
    }

    li ul {
      background: $white;
      box-shadow: 1px 1px 2px $gray-400;
    }
  }

.active {
  border-bottom:3px solid #f79c00;
  .active {
    border: none;
    background: $light;
  }
}
}



