.manufacturers {
  .itemWrapper {
    border: 1px solid $light;
    &:hover {
      border-color: $primary;
    }
  }
  .item {
    min-height: 200px;
    @include media-breakpoint-up(lg) {
      min-height: 240px;
    }
  }
}