// Smartphone
.img-fit {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.img_effect a {
  display: block;
  overflow: hidden !important;
}
.zoom img {
  transform: scale(1);
  transition: 0.2s ease-out;

}
.zoom img:hover {
  transform: scale(1.1);
}

.bw a img {
  filter: grayscale(1) !important;
  transition: 0.2s ease-out;
}

.bw a img:hover {
  filter: grayscale(0) !important;
}


//DOIT Implement animation
.animation-blur {
 filter: grayscale(0);
  animation: bluring 3s infinite;
}

.animation-blur:nth-of-type(2) {
  animation-delay: 3s;
}
.animation-blur:nth-of-type(3) {
  animation-delay: 6s;
}
.animation-blur:nth-of-type(4) {
  animation-delay: 9s;
}

@-webkit-keyframes bluring {
  0%,
  100%  { filter: grayscale(1); }
  50% { filter: grayscale(0); }
}


// Smartphone quer
@include media-breakpoint-up(sm) {
}


// Tablet
@include media-breakpoint-up(md) {
}


// Laptop
@include media-breakpoint-up(lg) {
}


// Desktop
@include media-breakpoint-up(xl) {
}
