/* ==========================================================================
   Gridiculous
   ========================================================================== */
.gridiculous {
*{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;-ms-box-sizing:border-box;box-sizing:border-box}
img{max-width:100%;height:auto;display:block;margin-left:auto;margin-right:auto}
.section{margin:0 auto;max-width:1200px;width:100%}
.wfull{width:100%;max-width:100%}
.w320{max-width:320px}
.w640{max-width:640px}
.w960{max-width:960px}
.row{width:100%;}
.row .row{width:auto;margin:0 -20px}
  .row {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .c1{width:8.33%}
  .c2{width:16.66%}
  .c3{width:25%}
  .c4{width:33.33%}
  .c5{width:41.66%}
  .c6{width:50%}
  .c7{width:58.33%}
  .c8{width:66.66%}
  .c9{width:75%}
  .c10{width:83.33%}
  .c11{width:91.66%}
  .c12{width:100%}
  .c1,.c2,.c3,.c4,.c5,.c6,.c7,.c8,.c9,.c10,.c11,.c12{min-height:1px;float:left;padding-left:20px;padding-right:20px;position:relative}
  .s1{margin-left:8.33%}
  .s2{margin-left:16.66%}
  .s3{margin-left:25%}
  .s4{margin-left:33.33%}
  .s5{margin-left:41.66%}
  .s6{margin-left:50%}
  .s7{margin-left:58.33%}
  .s8{margin-left:66.66%}
  .s9{margin-left:75%}
  .s10{margin-left:83.33%}
  .s11{margin-left:91.66%}
  .end{float:right!important}

  //tablet
  @media only screen and (max-width: 1000px) {
    .c1, .c2, .c3, .c4, .c5, .c6, .c7, .c8, .c9, .c10, .c11, .c12 {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
      margin-bottom: 20px
    }
    .row > div:last-child {
      margin-bottom: 0 !important;
    }
  }
  //small
  @media only screen and (max-width: 1150px) {
    .row .row .c1, .row .row .c2, .row .row .c3, .row .row .c4, .row .row .c5, .row .row .c6, .row .row .c7, .row .row .c8, .row .row .c9, .row .row .c10, .row .row .c11, .row .row .c12 {
      width: 100%;
      margin-bottom: 20px
    }
    .row .row > div:last-child {
      margin-bottom: 0 !important
    }
  }
  
}
