.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  iframe, div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  }
}
.videoPlaceholder {
  border: 1px solid $light;
}
.videoWarning {
  background: rgba(0,0,0,.8);
  h1, h2, h3, h4, p, a, li {
    color: $white;
  }

  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

}