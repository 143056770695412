// grid
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 1200px,
        xl: 1400px
);

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 1140px,
        xl: 1340px
);

//layout
// $spacer: 1rem !default;


//colors
$primary: #f79c00;
$primaryDark: #e17a07;
$primarySoft: #ffb261;

$dark: #000;
$medium: #4d4d4d;
$mediumLight: #969696;
$light: #e3e3e3;
$white: #fff;



$theme-colors: (
        "primaryDark" : $primaryDark,
        "primarySoft" : $primarySoft,
        "dark": $dark,
        "medium": $medium,
        "mediumLight": $mediumLight,
        "Light": $light,
        "white": $white,
);




$enable-responsive-font-sizes: true;
$font-size-base: 1.2rem;
$font-size-lg: $font-size-base * 1.25;
$font-size-sm: $font-size-base * .875;
$font-family-base: 'Changa';
$font-weight-base: 200;
$font-weight-normal: 200;
$line-height-base: 1.6;

$h1-font-size: $font-size-base * 2.2;
$h2-font-size: $font-size-base * 1.8;
$h3-font-size: $font-size-base * 1.4;
$h4-font-size: $font-size-base * 1.18;
.text-small {
        font-size: $font-size-sm;
}

$headings-font-weight:        500;
$headings-color:              $primaryDark;

$input-border-radius:                   0;
//$paragraph-margin-bottom:   1rem !default;
$body-color:                $medium;

$btn-border-radius:           none;
$btn-border-radius-lg:        none;
$link-color: $primaryDark;

$btn-font-weight: 500;
$btn-font-size: 1.3rem;
// bootstrap config will be imported later on from
// node_modules/bootstrap/scss/_variables.scss
