@import "sonnenschutzOverrides";
@import "bootstrap/scss/bootstrap";

//vendor
@import "owl.carousel/dist/assets/owl.carousel";
@import "owl.carousel/dist/assets/owl.theme.default";
@import '@fancyapps/fancybox/dist/jquery.fancybox';
@import "@fortawesome/fontawesome-free/css/all";
@import "mmenu-js/dist/mmenu";
@import "@fortawesome/fontawesome-free/css/all";
@import "@geedmo/yamm/src/yamm";

//templates / Modules
@import "components/fonts";
@import "components/sizing";
@import "components/img-effects";

//old styles
@import 'components/gridiculous';
@import "components/awningConfigurator";
@import "components/search";
@import "components/manufacturers";
@import "components/megamenu";
@import "components/header";
@import "components/navigation";
@import "components/footer";
@import "components/slider";
@import "components/video";
@import "components/cards";




// Images
.fit {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

//fix Error Messages Output
.alert ul {
  margin-bottom: 0;
}

//accordeon
.closed {
  display: none;
}

html {
  scroll-behavior: smooth;
}