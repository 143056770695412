.image img {
  height: 120px;
}

.yamm .dropdown.yamm-fw .dropdown-menu {
  top: 61px; // fix gap bug
  left: 0;
  right: 0;
  border-image: conic-gradient(#fff 0 0) fill 0 / / 0 100vw;
}

.dropdown-menu {
  display: none;
  visibility: hidden;
  opacity:0;
  height: 0px;
  transition:.5s ease all;
}
.dropdown-menu.show {
  display: block;
  visibility: visible;
  opacity:1;
  height: auto;
  transition:.5s ease all;
}

.megamenu {
  .footer {
    border-image: conic-gradient($light 0 0) fill 0 / / 0 100vw;
  }
  .nav-item {
    .nav-link {
      color: $white !important;
      font-weight: $headings-font-weight;
      text-transform: uppercase;
      &:hover {
        color: $white !important;
        background: $primaryDark;
      }
    }
    .nav-linklist {
      ul {
        @include media-breakpoint-up(lg) {
          column-count: 2;
        }
      }
      .nav-link {
        display: block;
        color: $body-color !important;
        font-weight: $font-weight-normal;
        padding: 0;
        text-transform: none !important;
      }
    }
  }
  .level-2 {
    .nav-link {
      text-decoration: none;
      transition:.5s ease all;

      &:hover {
        text-decoration: none;
        background-color: $light !important;
        transition:.5s ease all;
      }
      .link {
        color: $primary;
      }
      .description {
        font-weight:$font-weight-normal;
        text-transform: none;
        font-size: $font-size-base;
        color: $body-color;
      }
    }
  }

}