/* changa-200 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Changa';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/changa-v20-latin-300.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
  url('../fonts/changa-v20-latin-300.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* changa-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Changa';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/changa-v20-latin-500.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
  url('../fonts/changa-v20-latin-500.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


.megamenu {
  .link {
    font-size: $font-size-base * 1.1;
  }
}


.btn {
  text-transform: uppercase;
  font-weight: 500;
}

.strong {
  font-weight: $headings-font-weight;
}

h1:last-of-type, h2:last-of-type, h3:last-of-type, h4:last-of-type, h5:last-of-type, h6:last-of-type, p:last-of-type, li:last-of-type, a:last-of-type {
  margin: 0;
}